import { useEffect } from "react";
import AOS from "aos";
import LogoApp from "./assets/img/logo.png";
import ImgSection1 from "./assets/img/img-section-1.png";
import ImgSection2 from "./assets/img/img-section-2.png";
import ImgSection3 from "./assets/img/img-section-3.png";
import IconTelegram from "./assets/img/icon-telegram.png";
import IconTwitter from "./assets/img/icon-twitter.png";
import ImgFooter from "./assets/img/img-footer.png";
import "./App.scss";
import "aos/dist/aos.css";

function App() {
  const handleScrollToSection = (key) => {
    const sectionAbout = document.querySelector(".section-about");
    const sectionTokenomics = document.querySelector(".section-tokenomics");
    switch (key) {
      case 1:
        return sectionAbout.scrollIntoView({ behavior: "smooth" });
      default:
        return sectionTokenomics.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className="App">
      <header className="page-header">
        <div className="container">
          <div className="logo-app">
            <img src={LogoApp} alt="logo" />
          </div>
          <ul className="nav-menu">
            <li>
              <a href="">HOME</a>
            </li>
            <li onClick={() => handleScrollToSection(1)}>
              <a href="#">ABOUT</a>
            </li>
            <li onClick={() => handleScrollToSection(2)}>
              <a href="#">TOKENOMICS</a>
            </li>
          </ul>
          <a href="" className="btn-buy-now">
            BUY NOW
          </a>
        </div>
      </header>
      <main className="page-main">
        <section data-aos="fade-up" className="section-intro">
          <div className="container">
            <div className="content">
              <h2 className="title-1">Shrek on</h2>
              <h2 className="title-2">solana</h2>
              <p className="description">
                Shrek lives as a recluse in a remote swamp in the fairy-tale
                land of Duloc. Now came to the world through the Solana chain!!
              </p>
              <p className="sub-description">Contract: 0x00000000000000000</p>
            </div>
            <div className="img-intro">
              <img src={ImgSection1} alt="img-intro" />
            </div>
          </div>
        </section>
        <section data-aos="fade-up" className="section-about">
          <div className="container">
            <div className="box-wrapper">
              <div className="content">
                <h3 className="title">About</h3>
                <p className="description">
                  Shrek lives as a recluse in a remote swamp in the fairy-tale
                  land of Duloc. Although Shrek is a towering, green ogre with a
                  fearsome appearance, he has a kind heart. He came to the world
                  through the Solana chain with the desire to touch the world
                  with his altruism and kindness
                </p>
                <a href="" className="btn-buy-now">
                  Buy now
                </a>
              </div>
              <div className="img-about">
                <img src={ImgSection2} alt="img-about" />
              </div>
            </div>
          </div>
        </section>
        <section data-aos="fade-up" className="section-tokenomics">
          <div className="container">
            <div className="img-tokenomics">
              <img src={ImgSection3} alt="img-tokenomics" />
            </div>
            <div className="content">
              <h3 className="title">TOKENOMICS</h3>
              <p className="description">
                Out of compassion, Shrek on Solana does not apply taxes, smart
                contract ownership is renounced and 100% liquidity will also be
                burned. Return full power to decide the project's fate to the
                community
              </p>
              <a href="" className="btn-check-it-out">
                CHECK IT OUT
              </a>
            </div>
          </div>
        </section>
        <section data-aos="fade-up" className="section-community">
          <div className="container">
            <h3 className="title">join us now</h3>
            <p className="description">
              Come to the remote swamp in the fairy-tale land of Duloc to
              receive the latest news
            </p>
            <div className="socials">
              <a
                href="https://t.me/ShrekSol"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IconTelegram} alt="icon-tele" />
              </a>
              <a
                href="https://twitter.com/ShrekSol"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IconTwitter} alt="icon-tw" />
              </a>
            </div>
          </div>
        </section>
      </main>
      <footer className="page-footer">
        <div className="container">
          <div className="img-footer">
            <img src={ImgFooter} alt="img-footer" />
          </div>
          <p>© 2023 by Shrek on Solana. All rights reserved!</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
